if (document.readyState !== 'loading') {
  initImageOverlay();
} else {
  document.addEventListener('DOMContentLoaded', initImageOverlay);
}

function initImageOverlay() {
  document.querySelectorAll('.image-overlay-container').forEach((container) => {
    container.querySelectorAll('.image-overlay-content').forEach((imageOverlayContainer) => {
      imageOverlayContainer.setAttribute('tabindex', '-1');

      // handler when esc is press on overlay
      imageOverlayContainer.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          event.target?.querySelector('.close-overlay-btn')?.click();
        }
      });
    });

    const openButtons = container.querySelectorAll('.open-overlay-btn');
    let lastFocusedElement = null;

    openButtons.forEach((openButton) => {
      const overlayContentId = openButton.getAttribute('aria-controls');
      const overlayContent = document.getElementById(overlayContentId);
      const closeButton = overlayContent?.querySelector('.close-overlay-btn');

      function openOverlay() {
        lastFocusedElement = document.activeElement;
        toggleOverlayVisibility(overlayContent, true);
        updateButtonAccessibility(openButton, true);
        handleOverlayTransition(overlayContent, closeButton);
      }

      function closeOverlay() {
        toggleOverlayVisibility(overlayContent, false);
        updateButtonAccessibility(openButton, false);
        if (lastFocusedElement) lastFocusedElement.focus();
      }

      openButton.addEventListener('click', openOverlay);
      if (closeButton) {
        closeButton.addEventListener('click', closeOverlay);
        closeButton.addEventListener('keydown', (event) => {
          if (event.key === 'Escape') {
            event.target?.click();
          }
        });
      }
    });
  });
}

function updateButtonAccessibility(button, isExpanded) {
  button.setAttribute('aria-expanded', String(isExpanded));
  button.setAttribute('tabindex', isExpanded ? '-1' : '0');
}

function handleOverlayTransition(overlayContent, closeButton) {
  const handleTransitionEnd = () => {
    if (closeButton) closeButton.focus();
    overlayContent.removeEventListener('transitionend', handleTransitionEnd);
  };
  overlayContent.addEventListener('transitionend', handleTransitionEnd);
}

function toggleOverlayVisibility(overlayContent, isVisible) {
  overlayContent.classList.toggle('opacity-0', !isVisible);
  overlayContent.classList.toggle('invisible', !isVisible);
  overlayContent.setAttribute('aria-hidden', String(!isVisible));
  updateFocusableElements(overlayContent, isVisible);
}

function updateFocusableElements(overlayContent, isVisible) {
  const container = overlayContent.closest('.image-overlay-container')?.querySelector('.image-container');
  if (!container) return;

  const focusableElements = container.querySelectorAll('button, [href], input, select, textarea, [tabindex]');
  focusableElements.forEach((el) => {
    if (isVisible) {
      el.setAttribute('data-original-tabindex', el.getAttribute('tabindex') || '0');
      el.setAttribute('tabindex', '-1');
    } else {
      restoreOriginalTabindex(el);
    }
  });
}

function restoreOriginalTabindex(element) {
  const originalTabindex = element.getAttribute('data-original-tabindex');
  if (originalTabindex !== null) {
    element.setAttribute('tabindex', originalTabindex);
    element.removeAttribute('data-original-tabindex');
  } else {
    element.removeAttribute('tabindex');
  }
}
