if (document.readyState !== 'loading') {
  initPopover();
} else {
  document.addEventListener('DOMContentLoaded', initPopover);
}

function initPopover() {
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach(() => {
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
      if (popoverTriggerList.length) {
        popoverTriggerList.forEach((popoverTriggerEl) => {
          if (popoverTriggerEl.dataset.listenerAdded) {
            return;
          }

          // eslint-disable-next-line no-undef
          const popover = bootstrap.Popover.getOrCreateInstance(popoverTriggerEl);

          popoverTriggerEl.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
              popover.hide();
            }

            toggleAttribute(popover);
          });

          popoverTriggerEl.addEventListener('click', () => {
            toggleAttribute(popover);
          });

          popoverTriggerEl.dataset.listenerAdded = 'true';
        });
      }
    });
  });
  observer.observe(window.document.body, { childList: true, subtree: true });
}

function toggleAttribute(bsPopoverEl) {
  if (bsPopoverEl.tip.classList.contains('show')) {
    addAttribute(bsPopoverEl._element);
  } else {
    removeAttribute(bsPopoverEl._element);
  }
}

function addAttribute(el) {
  el.setAttribute('aria-expanded', 'true');
  el.setAttribute('aria-haspopup', 'true');
}

function removeAttribute(el) {
  el.setAttribute('aria-expanded', 'false');
  el.setAttribute('aria-haspopup', 'false');
}
